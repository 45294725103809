<template>
  <v-container
      id="bamboo-board"
      fluid
      tag="section"
    >
      <v-card
        class="pa-5"
      >
        <v-card-title>
          <div>
            <div>Zendesk Board (tasks & zendesk_users)</div>
            <v-subheader>Populate Tickets and Zendesk Users</v-subheader>
          </div>
          <v-spacer />
          <v-switch
            class="mt-0 mr-3"
            v-model="migrate"
            label="Migrate"
          ></v-switch>
          <v-btn class="main" @click="auth" :loading="loading" :disabled="!valid || loading">
            Authenticate
            <v-icon right>mdi-send</v-icon>
          </v-btn>
          <v-btn :loading="loading" :disabled="loading" @click="showCronDialog({dialog: true, type: 'run_zendesk', interval: 'Daily'})" color="main">
            Crons
            <v-icon  size="16" right dark>mdi-clock-time-eight-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <v-form
            style="width: 100%;"
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col cols='12' md="3">
                <v-textarea
                  v-model="form.token"
                  :rules="[rules.required]"
                  prepend-icon="mdi-key"
                  :loading="loading"
                  label="API Token"
                  auto-grow
                  rows="1"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
              <v-col cols='12' md="3">
                <v-textarea
                  v-model="form.emails"
                  :rules="[rules.required]"
                  prepend-icon="mdi-email"
                  :loading="loading"
                  label="Email"
                  auto-grow
                  rows="1"
                  hide-details="auto"
                  @input="changeAccountEmail"
                ></v-textarea>
              </v-col>
              <v-col cols='12' md="3">
                <v-textarea
                  v-model="form.subdomain"
                  :rules="[rules.required]"
                  prepend-icon="mdi-earth"
                  :loading="loading"
                  label="Subdomain"
                  auto-grow
                  rows="1"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
              <v-col cols='12' md="3">
                <v-textarea
                  v-model="form.company_id"
                  :rules="[rules.required]"
                  prepend-icon="mdi-application"
                  :loading="loading"
                  label="Company Name"
                  auto-grow
                  rows="1"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-title>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            class="mb-5"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                :loading="loading" 
                :disabled="loading" 
                class="mr-2" 
                @click="readTasks" 
                v-bind="attrs"
                v-on="on"
                color="main">
                <v-icon size="16">mdi-database-search</v-icon>
              </v-btn>
            </template>
            <span>Read Tasks</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                :loading="loading" 
                :disabled="loading" 
                class="mr-2" 
                @click="readUsers" 
                v-bind="attrs"
                v-on="on"
                color="main">
                <v-icon size="16">mdi-database-search</v-icon>
              </v-btn>
            </template>
            <span>Read Users</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                :loading="loading" 
                :disabled="loading || selectedItems.length == 0 && items.length == 0" 
                @click="downloadCSV" 
                v-bind="attrs"
                v-on="on"
                color="main">
                <v-icon size="16">mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Download</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
        <v-data-table
          v-model="selectedItems"
          :loading="loading"
          :headers="headers"
          :items="items"
          :items-per-page="page"
          item-key="id"
          :search="search"
          show-select
          @update:items-per-page="getPageNum"
        > 
          <template v-slot:item.email="{ item }">
            <span v-html="highlightText(item.email)"></span>
          </template>
          <template v-slot:item.assignee="{ item }">
            <span v-html="highlightText(item.assignee)"></span>
          </template>
          <template v-slot:item.description="{ item }">
            <span v-html="highlightText(item.description)"></span>
          </template>
          <template v-slot:item.created_at="{ item }">
            <span>{{ item.created_at | beautifyDateTime }}</span>
          </template>
          <template v-slot:item.due_at="{ item }">
            <span>{{ item.due_at | beautifyDateTime }}</span>
          </template>
          <template v-slot:item.has_2fa="{ item }">
            <v-chip :class="{'success': item.has_2fa}">{{item.has_2fa ? 'Yes' : 'No'}}</v-chip>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :class="{'success': item.status}">{{item.status ? 'Ok' : 'No'}}</v-chip>
          </template>
          <template v-slot:item.risk_level="{ item }">
            <v-chip :color="levelColor(item.risk_level)" dark>
              <div class="subtitle-2 text-capitalize">{{ item.risk_level || 'low' }}</div>
            </v-chip>
          </template>
        </v-data-table>

            <!-- migration sheet -->
        <v-card v-if="migrate" outlined class="pa-2 mt-4">
          <v-card-title>
            Migration Sheet
          </v-card-title>
          <v-card-text>
            <div class="subtitle-1">Old credentials</div>
            <v-form
              ref="oldForm"
              v-model="oldCreds"
            >
              <v-row>
                <v-col cols=12 md=4>
                  <v-textarea
                    v-model="oldForm.token"
                    :rules="[rules.required]"
                    prepend-icon="mdi-key"
                    :loading="loading"
                    label="API Token (Old)"
                    auto-grow
                    rows="1"
                    hide-details="auto"
                  ></v-textarea>
                </v-col cols=12 md=4>
                <v-col>
                  <v-textarea
                    v-model="oldForm.email"
                    :rules="[rules.required]"
                    prepend-icon="mdi-email"
                    :loading="loading"
                    label="Email (Old)"
                    auto-grow
                    rows="1"
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
                <v-col cols=12 md=4>
                  <v-textarea
                    v-model="oldForm.subdomain"
                    :rules="[rules.required]"
                    prepend-icon="mdi-earth"
                    :loading="loading"
                    label="Subdomain (Old)"
                    auto-grow
                    rows="1"
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>

            <div class="subtitle-1 mt-5">New credentials</div>
            <v-form
              ref="newForm"
              v-model="newCreds"
            >
              <v-row>
                <v-col cols=12 md=4>
                  <v-textarea
                    v-model="newForm.token"
                    :rules="[rules.required]"
                    prepend-icon="mdi-key"
                    :loading="loading"
                    label="API Token (New)"
                    auto-grow
                    rows="1"
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
                <v-col cols=12 md=4>
                  <v-textarea
                    v-model="newForm.email"
                    :rules="[rules.required]"
                    prepend-icon="mdi-email"
                    :loading="loading"
                    label="Email (New)"
                    auto-grow
                    rows="1"
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
                <v-col cols=12 md=4>
                  <v-textarea
                    v-model="newForm.subdomain"
                    :rules="[rules.required]"
                    prepend-icon="mdi-earth"
                    :loading="loading"
                    label="Subdomain (New)"
                    auto-grow
                    rows="1"
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="main" @click="migrateZendesk" :loading="loading" :disabled="!oldCreds || !newCrdes || loading">
            Migrate
            <v-icon right>mdi-send</v-icon>
          </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { BASE_API, Get, Post } from '@/api'
  import { downloadCSV, beautifyDateTime, highlightText, levelColor } from '@/util'
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'BambooBoard',

    data () {
      return {
        search: '',
        loading: false,
        snackbar: false,
        message: '',
        color: 'success',
        migrate: false,
        valid: true,
        form: {
          token: '',
          emails: '',
          subdomain: '',
          company_id: ''
        },
        oldCreds: true,
        oldForm: {
          token: '',
          email: '',
          subdomain: '',
        },
        newCrdes: true,
        newForm: {
          token: '',
          email: '',
          subdomain: '',
        },
        items: [],
        users: [],
        tasks: [],
        selectedItems: [],
        headers: [],
        taskHeaders: [
          {
            text: 'Task',
            value: 'subject'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            text: 'Description',
            value: 'description'
          },
          {
            text: 'Risk Level',
            value: 'risk_level'
          },
        ],
        userHeaders: [
          {
            text: 'Email',
            value: 'email'
          },
          {
            text: 'Fullname',
            value: 'fullname'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            text: 'Has 2fa',
            value: 'has_2fa'
          },
          {
            text: 'Company',
            value: 'company_id'
          },
        ],
        rules: {
          required: value => {
            return !!value || 'This field is required.'
          },
        }
      }
    },

    filters: {
      beautifyDateTime
    },

    computed: {
      ...mapState(['page', 'userId']),
    },

    methods: {
      ...mapActions(['showCronDialog']),

      highlightText,
      levelColor,

      showSnack(res) {
        this.$store.commit('snackbar/setSnack', res)
      },

      async readTasks () {
        this.loading = true
        const res = await Get(`admin/tasks/read/tasks/%`)
        this.headers = this.taskHeaders
        this.tasks = res.items
        this.items = [...this.tasks]
        this.loading = false
        this.showSnack(res)
      },

      async readUsers () {
        this.loading = true
        const res = await Get(`admin/tasks/read/users/${this.form.company_id || '%%'}`)
        this.headers = this.userHeaders
        this.users = res.items
        this.items = [...this.users]
        this.loading = false
        this.showSnack(res)
      },

      downloadCSV () {
        const filename = 'Zendesk.csv'
        if (this.selectedItems.length) {
          downloadCSV(this.selectedItems, filename)
        } else {
          downloadCSV(this.items, filename)
        }
      },
          
      getPageNum (_page) {
        localStorage.setItem('page', _page)
      },

      showCron () {
        this.showCronDialog({dialog: true, type: 'run_zoom', interval: 'Daily'})
      },

      async auth () {
        this.loading = true
        const res = await Post('admin/tasks/register', this.form)
        this.loading = false
        this.showSnack(res)
      },

      changeAccountEmail() {
        if (this.form.emails.includes('@')) {
          this.form.company_id = this.form.emails.split('@')[1]
        }
      },

      async migrateZendesk () {
        this.loading = true
        const data = {
          old: this.oldForm, 
          new: this.newForm
        }
        const res = await Post('admin/tasks/migrate', data)
        this.loading = false
        this.showSnack(res)
      }
    }
  }
</script>